
html {
  /* overflow-x: hidden;
  overflow-y: auto; */
}
body {
  width: 100vw;
  overflow-x: hidden;
  padding-left: calc(100vw - 100%);
}
body {
  margin: 0;
  padding: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  font-family: sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width: 1230px;
}
@media screen and (max-width: 1230px) {
    body {
        overflow-x: auto;
    }
}
@font-face {
    font-family: 'MicrosoftYaHeiLight';
    src: url(/static/media/msyh.eb525dcb.ttf);
  }
@font-face {
    font-family: 'SourceHanSansCN-Light';
    src: url(/static/media/SourceHanSansCN-Light.424662ea.otf);
  }  
@font-face {
    font-family: 'SourceHanSansCN-Regular';
    src: url(/static/media/SourceHanSansCN-Regular.11c302dd.otf);
  }  
@font-face {
    font-family: 'SourceHanSansCN-Medium';
    src: url(/static/media/SourceHanSansCN-Medium.4abbfb49.otf);
  }
@font-face {
    font-family: 'SourceHanSansCN-Normal';
    src: url(/static/media/SourceHanSansCN-Normal.4bf258e4.otf);
  }  
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

